<template>
  <b-container>
    <b-row>
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
          <validation-observer
            ref="storeRules"
            tag="form"
            v-slot="{ invalid }"
          >
            <b-form @submit.prevent="handleSubmit()">
              <h3>Agregar Tienda</h3>
              <div class="d-flex justify-content-start my-2">
                <base-cropper
                  :model="store"
                  @cropped-image="store.logo = $event"
                />
              </div>

              <b-form-group
                class="mb-2"
                label="Nombre de la tienda"
              >
                <validation-provider
                  #default="{ errors, valid }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input
                    v-model="store.name"
                    :state="errors.length > 0 ? false: (valid ? true : null)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mb-2"
                label="¿Vendes en linea o en tu tienda fisica?"
              >
                <validation-provider
                  #default="{ errors, valid }"
                  name="Categoria de tienda"
                  rules="required"
                >
                  <v-select 
                    v-model="store.category"
                    :options="storeCategory"
                    label="text"
                    clearable
                    placeholder="Selecciona la categoria de tienda"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mb-2"
                label="Tipo de tienda"
              >
                <validation-provider
                  #default="{ errors, valid }"
                  name="Tipo de tienda"
                  rules="required"
                >
                  <v-select 
                    v-model="store.store_type"
                    :options="storeType"
                    label="text"
                    clearable
                    placeholder="Selecciona el tipo de tienda"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mb-2"
                label="¿A qué hora cierras?"
              >
                <validation-provider
                  #default="{ errors, valid }"
                  name="Hora de cierre"
                  rules="required"
                >
                  <v-select
                    v-model="store.end_of_day_restocking_time"
                    :options="timeArray"
                    :reduce="val => val.type"
                    label="name"
                    clearable
                    placeholder="Selecciona el mejor horario para ti"
                    :state="errors.length > 0 ? false: (valid ? true : null)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Selecciona los tipos de pago que acepta esta tienda"
                label-for="is-edible"
              >
                <b-form-checkbox
                  id="cash"
                  v-model="acceptedPaymentTypes"
                  class="custom-control-primary"
                  name="check-button"
                  value="cash"
                  switch
                >
                  Efectivo
                </b-form-checkbox>
                <b-form-checkbox
                  id="walleat"
                  v-model="acceptedPaymentTypes"
                  class="custom-control-primary"
                  name="check-button"
                  value="walleat"
                  switch
                >
                  Walleat Pay
                </b-form-checkbox>
                <b-form-checkbox
                  id="bankcard"
                  v-model="acceptedPaymentTypes"
                  class="custom-control-primary"
                  name="check-button"
                  value="bankcard"
                  switch
                >
                  Tarjeta bancaria
                </b-form-checkbox>
                <b-form-checkbox
                  id="reward_points"
                  v-model="acceptedPaymentTypes"
                  class="custom-control-primary"
                  name="check-button"
                  value="reward_points"
                  switch
                >
                  Puntos de recompensa
                </b-form-checkbox>
                <b-form-checkbox
                  id="bank_deposit"
                  v-model="acceptedPaymentTypes"
                  class="custom-control-primary"
                  name="check-button"
                  value="bank_deposit"
                  switch
                >
                  Transferencia o depósito bancario
                </b-form-checkbox>
                <b-form-checkbox
                  id="codi"
                  v-model="acceptedPaymentTypes"
                  class="custom-control-primary"
                  name="check-button"
                  value="codi"
                  switch
                >
                  CoDi
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                v-if="(store.category === 'online' || store.category === 'hybrid') && (acceptedPaymentTypes.includes('walleat') || acceptedPaymentTypes.includes('reward_points'))"
                class="mb-2"
                label="Mensaje de cobro para ventas en linea"
              >
                <b-form-input
                  v-model="store.codi_message"
                  required
                />
              </b-form-group>

              <div v-show="keepOldAddress">
                <b-col>
                  <p v-if="store.address_attributes.street" class="d-flex flex-column">
                    <span class="text-muted">
                      ¿La dirección de tu tienda es la siguiente? Si no, modifícala
                    </span>
                    {{ store.address_attributes.street }} {{ store.address_attributes.ext_number }},
                    CP {{ store.address_attributes.postal_code }}, {{ store.address_attributes.city }},
                    {{ store.address_attributes.state }}, {{ store.address_attributes.country }}
                  </p>
                  <b-button
                    class="mb-3"
                    @click="changeAddress()"
                  >
                    <span v-if="store.address_attributes.street">
                      Modificar dirección
                    </span>
                    <span v-else>
                      Agregar dirección
                    </span>
                  </b-button>
                </b-col>
              </div>
              <div v-show="!keepOldAddress">
                <address-form-model
                  info-type
                  @getAddress="setAddressForEdit($event)"
                />
              </div>
              <b-button
                class="mr-1"
                variant="primary"
                type="submit"
                :disabled="invalid"
              >
                Guardar
              </b-button>
              <b-button
                variant="outline-secondary"
                :to="{ name: 'establishment-sucursales', params: { id: $route.params.id } }"
              >
                Cancelar
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col>
        <div class="text-center">
          <b-img
            :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
            width="600"
            fluid
          />
        </div>
      </b-col>
    </b-row>
    <b-modal v-model="showModal" no-close-on-backdrop hide-footer>
      <validation-observer
        ref="aliasRules"
        v-slot="{ invalid }"
      >
      <h4 v-if="!establishmentAccounts.length" class="text-center">Ingresa los datos para la nueva cuenta de Stripe</h4>
      <h4 v-if="establishmentAccounts.length" class="text-center">Selecciona una de las cuenta de Stripe</h4>
        <b-form
          class="mt-2"
          @submit.prevent="handleStripeAccount()"
        >
          <b-form-group
            label="Alias"
            label-for="alias"
            v-if="!establishmentAccounts.length"
          >
            <validation-provider
              #default="{ errors }"
              name="alias"
              rules="required"
            >
              <b-form-input
                v-model="aliasAccount"
                :state="errors.length > 0 ? false:null"
                placeholder="Alias"
                name="alias"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Tipo de negocio"
            label-for="business-type"
            v-if="!establishmentAccounts.length"
          >
            <validation-provider
              #default="{ errors }"
              name="business-type"
              rules="required"
            >

              <v-select
                v-model="businessType"
                :options="[
                  { value: 'individual', text: 'Individual' },
                  { value: 'company', text: 'Empresa' },
                ]"
                :state="errors.length > 0 ? false:null"
                label="text"
                clearable
                placeholder="Tipo de negocio"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Cuentas Disponibles"
            label-for="alias"
            v-if="establishmentAccounts.length"
          >
            <validation-provider
              #default="{ errors }"
              name="alias"
              rules="required"
            >
              <v-select 
                v-model="aliasAccount"
                :options="establishmentAccounts"
                :state="errors.length > 0 ? false:null"
                label="alias"
                clearable
                :reduce="val => val.id"
                placeholder="Selecciona una cuenta para la tienda"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button
              variant="primary"
              @click="handleStripeAccount()"
              :disabled="invalid"
              class="mr-1"
            >
              Agregar
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="showModal = false"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-container>
</template>

<script>
import AddressFormModel from '@/@core/components/AddressFormModel.vue'
import {
  BContainer,
  BCard,
  BButton,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BImg,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import BaseCropper from '@/@core/components/BaseCropper.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    AddressFormModel,
    BContainer,
    BCard,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BImg,
    BaseCropper,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      acceptedPaymentTypes: [],
      timeArray: [],
      store: {
        establishment_id: this.$route.params.id,
        name: null,
        logo: null,
        category: null,
        store_type: null,
        codi_message: null,
        end_of_day_restocking_time: null,
        accepted_payment_types: [],
        address_attributes: {},
      },
      storeCategory: [
        { value: 'physical', text: 'Tienda fisica' },
        { value: 'online', text: 'Tienda en línea' },
        { value: 'hybrid', text: 'Tienda híbrida' },
      ],
      storeType: [
        { value: 'corner_shop', text: 'Tiendita de la esquina' },
        { value: 'school_cafeteria', text: 'Cafetería de la escuela' },
        { value: 'flower_store', text: 'Floreria' },
        { value: 'book_store', text: 'Librería' },
        { value: 'hardware_store', text: 'Ferretería' },
        { value: 'tortilleria', text: 'Tortillería' },
        { value: 'coffee_shop', text: 'Cafetería' },
        { value: 'clothing_store', text: 'Tienda de ropa' },
        { value: 'water_purifier', text: 'Purificadora de agua' },
        { value: 'corporate_education', text: 'Educación corporativa' },
        { value: 'cleaning_hardware', text: 'Productos de limpieza' },
        { value: 'other', text: 'Otra' },
      ],
      keepOldAddress: true,
      showModal: false,
      aliasAccount: null,
      businessType: null,
      storeAccount: null
    }
  },
  mounted() {
    this.generateTimeArray()
  },
  computed: {
    ...mapGetters('stripe', ['establishmentAccounts']), //, 'storeAccount'
  },
  methods: {
    ...mapActions('stores', [
      'addStore',
      'editStore',
      'fetchStore',
    ]),
    ...mapActions('stripe', [
      'createEstablishmentAccount',
      'fetchEstablishmentAccounts',
      'createStoreAccount',
      'fetchEstablishmentAccountLink',
    ]),

    generateTimeArray() {
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : `${i}`;
        const timeObject = {
          type: `${hour}:00:00`,
          name: i === 0 ? '12:00 AM' : i < 12 ? `${i}:00 AM` : i === 12 ? '12:00 PM' : `${i - 12}:00 PM`,
        };
        this.timeArray.push(timeObject);
      }
    },

    setAddressForEdit(x) {
      this.store.address_attributes = x
    },
    changeAddress() {
      this.keepOldAddress = !this.keepOldAddress
    },
    handleStripeAccount(){
      if(this.establishmentAccounts.length>0){
        if(!this.aliasAccount){
          this.$swal({
            title: 'Selecciona una cuenta',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          return
        }
        this.createStoreAccount({
          store_account: {
            store_id: this.storeAccount,
            establishment_account_id: this.aliasAccount,
          }
        })
        .then(res => {
          this.$swal({
            title: 'Cuenta agregada!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$router.push({ name: 'establishment-sucursales', params: { id: this.$route.params.id } })
        })
        .catch(e => {
          const errorMessage = e.response.data.messages
          this.$swal({
            title: errorMessage,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
      }else{
        this.$refs.aliasRules.validate().then(success => {     
        if (success) {
          this.$swal({
            title: 'Creando cuenta...',
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            },
          })
          this.createEstablishmentAccount({
            establishment_account: {
              establishment_id: this.$route.params.id,
              alias: this.aliasAccount,
              country: 'MX',
              business_type: this.businessType,
            }
          }).then(res => {
            this.createStoreAccount({
              store_account: {
                store_id: this.storeAccount,
                establishment_account_id: res.stripe_establishment_account.id,
              }
            }).then(() => this.$swal.close())
            .catch((e) => {
              const errorMessage = e.response.data.messages
              this.$swal({
                title: errorMessage,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            this.fetchEstablishmentAccountLink({
              establishment_id: this.$route.params.id,
              stripe_account_id: res.stripe_establishment_account.stripe_account_id,
            }).then(res => {
              const url = res.account_link_url
              window.location.href = url
            })
          })
          .catch(e => {
            const message = e.response.data.messages
            this.$swal({
              title: message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
      }
    },
    handleSubmit() {

      this.$refs.storeRules.validate().then((success) => {
        if(success){
          this.store.accepted_payment_types = this.acceptedPaymentTypes
          this.store.end_of_day_restocking_time = this.store.end_of_day_restocking_time,

          this.addStore(this.store)
            .then((res) => {
              if(this.acceptedPaymentTypes.includes('bankcard') || this.acceptedPaymentTypes.includes('bank_deposit')) {
                this.storeAccount = res.id
                this.$swal({
                  title: 'Cargando cuentas...',
                  allowOutsideClick: false,
                  didOpen: () => {
                    this.$swal.showLoading()
                  },
                })
                this.fetchEstablishmentAccounts({ establishment_id: this.$route.params.id })
                  .then(res => {
                    this.$swal.close()
                    this.showModal = true
                  })
                  .catch(res => {
                    this.$swal.close()
                  })
              } else {
                // this.$router.push({ name: 'add-store-products', params: { id: response.id } })
                this.$router.push({ name: 'establishment-sucursales', params: { id: this.$route.params.id } })
              }
            })
            .catch((e) => {
              const message = e.response.data.messages
              const nameMessage = message.name
              this.$swal({
                title: nameMessage ? nameMessage : 'Error al agregar la tienda',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
